var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-scale-transition',[_c('BaseForm',{attrs:{"apiActions":"payment/payment.php","backRoute":'/paymentlist/' + this.$route.params.invoiceid,"newRecordTitle":"Novo plaćanje","listRefreshAction":"payment_list_needs_refresh","formData":[
      {
        title: 'PLATITELJ',
        rows: [
          {
            id: 1,
            fields: [
              {
                label: 'Naziv',
                type: 'text',
                name: 'sender_name'
              },
              {
                label: 'Adresa',
                type: 'text',
                name: 'sender_address'
              },
              {
                label: 'Mjesto',
                type: 'text',
                name: 'sender_location'
              },
              {
                label: 'IBAN platitelja',
                type: 'text',
                name: 'sender_iban'
              }
            ]
          }
        ]
      },
      {
        title: 'PLAĆANJE',
        rows: [
          {
            id: 2,
            fields: [
              {
                label: 'Datum plaćanja',
                type: 'date',
                name: 'payment_date'
              },
              {
                label: 'Model',
                type: 'text',
                name: 'irn_model'
              },
              {
                label: 'Poziv na broj',
                type: 'text',
                name: 'irn'
              },
              {
                label: 'Iznos',
                type: 'text',
                name: 'amount'
              }
            ]
          },
          {
            id: 3,
            fields: [
              {
                label: 'ID računa',
                type: 'invoice',
                name: 'invoice',
                initialValue: this.$route.params.invoiceid
              },
              {
                label: 'Opis',
                type: 'text',
                name: 'description'
              }
            ]
          }
        ]
      },
      {
        title: 'IDENTIFIKACIJA PLAĆANJA',
        rows: [
          {
            id: 4,
            fields: [
              {
                label: 'Vrsta',
                type: 'text',
                name: 'type',
                disabled: true,
                initialValue: 'RUČNI UNOS'
              },
              {
                label: 'Broj izvoda',
                type: 'text',
                name: 'statement_num'
              },
              {
                label: 'Datum izvoda',
                type: 'date',
                name: 'statement_date'
              },
              {
                label: 'IBAN računa',
                type: 'text',
                name: 'account_iban'
              }
            ]
          },
          {
            id: 5,
            fields: [
              {
                label: 'Valuta',
                type: 'text',
                name: 'currency'
              },
              {
                label: 'Referenca 1',
                type: 'text',
                name: 'reference1'
              },
              {
                label: 'Referenca 2',
                type: 'text',
                name: 'reference2'
              }
            ]
          }
        ]
      }
    ]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }